body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
.menuitem a
{
  background-color: red !important;
  color: yellow !important;
}

.ReactModal__Body--open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

.fade-enter {
  opacity: 0;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 0.5s ease-in-out 0.3s;
}

.fade-leave {

  width: 50%;
  opacity: 0;
}

.fade-leave.fade-leave-active {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}
.fade-appear {
  opacity: 0;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.calcGraph {
  font-weight: bold;
  font-family: 'Raleway'
}


